import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from "../../axios/utils";

const cancelTokenHandlerObject = {
  getPaymentGateways: createCancelTokenHandler('getPaymentGateways'),
  getPaymentGatewayTransactions: createCancelTokenHandler('getPaymentGatewayTransactions'),
  getPaymentGatewayActivitiesLog: createCancelTokenHandler('getPaymentGatewayActivitiesLog')
}

export function getPaymentGateways (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${ queries.join('&')}`

  return axios({
    url: `v1/admin/treasury/payment-gateways${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getPaymentGateways'].handleRequestCancellation().token
  })
}

export function getPaymentGateway (id) {

  return axios({
    url: `v1/admin/treasury/payment-gateways/${id}`,
    method: 'get'
  })
}

export function getPaymentGatewayProviders () {

  return axios({
    url: 'v1/admin/treasury/payment-gateway-providers',
    method: 'get'
  })
}

export function getPaymentGatewayBalance (id) {

  return axios({
    url: `v1/admin/treasury/payment-gateways/${id}/receive`,
    method: 'get'
  })
}

export function receivePaymentGatewayBalance (id, receive) {

  return axios({
    url: `v1/admin/treasury/payment-gateways/${id}/receive`,
    data: receive,
    method: 'post'
  })
}

export function getPaymentGatewayTransactions (id, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${ queries.join('&')}`

  return axios({
    url: `v1/admin/treasury/payment-gateways/${id}/transactions${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getPaymentGatewayTransactions'].handleRequestCancellation().token
  })
}

export function printPaymentGatewayTransactions (id, filters = {}, sorts = []) {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `?${ queries.join('&')}`

  return axios({
    url: `v1/admin/treasury/payment-gateways/${id}/transactions?export=pdf${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getPaymentGatewayActivitiesLog (id, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/admin/treasury/payment-gateways/${id}/activity-logs${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getPaymentGatewayActivitiesLog'].handleRequestCancellation().token
  })
}

export function insertPaymentGateway (paymentGateway) {

  return axios({
    url: 'v1/admin/treasury/payment-gateways',
    data: paymentGateway,
    method: 'post'
  })
}

export function editPaymentGateways (id, paymentGateway) {

  return axios.patch(`v1/admin/treasury/payment-gateways/${id}`, paymentGateway)
}

export function deletePaymentGateways (id, force = false) {

  return axios({
    url: `v1/admin/treasury/payment-gateways/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function restorePaymentGateways (id) {

  return axios({
    url: `v1/admin/treasury/payment-gateways/${id}/restore`,
    method: 'put'
  })
}
